import './App.scss'
import React, { useState, useEffect } from 'react'

function App () {
  const [scrolling, setScrolling] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true)

      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMouseEnter = () => {
    // TODO 
    // setIsHovered(true)
  }

  const handleMouseLeave = () => {
    // setIsHovered(false)
  }

  const handleLinkClickToTerms = () => {
    // TODO 补充链接
    window.open('https://calpal.topmusetech.com/user-agreement/terms_of_use.html', '_blank')
  }

  const handleLinkClickToPrivate = () => {
    // TODO 补充链接
    window.open('https://calpal.topmusetech.com/user-agreement/privacy_policy.html', '_blank')
  }

  const handleLocateToAppStore = () => {
    // TODO 补充链接
    // window.open('https://apps.apple.com/app/easytodo-daily-planer/id6504800291', '_blank')
  }

  return (
    <div className="App">
      <header className={scrolling ? "header header-scrolled" : "header"}>
        <div className='container'>
          <div className='navL'>
            <img src="./nav_logo.png" alt="" />
          </div>
          <div className='navR'>
            <div
              onClick={handleLocateToAppStore}
              className='store'>App Store</div>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ backgroundColor: isHovered ? 'rgba(30, 57, 48, 1)' : 'rgba(30, 57, 48, 1)' }}
              className='download'>
              Download

              {isHovered && (
                <div className='qrCode'>
                  <div className='triangle'>
                    <img src="./todo_triangle.png" alt="" />
                  </div>
                  <div className='qrcode'>
                    {/* TODO 二维码 */}
                    <img src="./qrcode.png" alt="" />
                  </div>
                  <div className='text'>
                    <div>
                      <img src="./scan.png" alt="" />
                    </div>
                    <div>Scan to download</div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </header>
      <div className='section'>
        <div className='left'>
          <img src="./left.png" alt="" />
        </div>
        <div className='right'>
          <div className='icon'>
            {/* TODO */}
            <img src="./icon.png" alt="" />
          </div>
          <div className='name'>Calpal</div>
          <div className='desc'>Welcome to CalPal, your personal health companion! We understand the importance of maintaining your figure, losing weight, and embracing a healthy diet. CalPal is designed to simplify your dietary management, making your health goals easily achievable. With our innovative AI technology, you can analyze food ingredients, log your meals, and track calories—all by simply taking a photo. Whether you're aiming for that ideal physique or looking to improve your eating habits, CalPal offers personalized support and guidance, transforming your healthy lifestyle into a fun and effortless journey.<br /> If you have any questions, you can contact us by email: <a href="mailto:support@calpal-mail.topmusetech.com">support@calpal-mail.topmusetech.com</a></div>
        </div>
      </div>
      <footer className='footer'>
        <div className='container'>
          <div className='footerL'>
            <div className='title'>Calpal</div>
            <div className='copyright'>© 2024 TOPMUSE PTE LTD. All rights reserved.</div>
          </div>
          <div className='footerR'>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToPrivate}
              >Privacy Policy</div>
            </div>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToTerms}
              >Terms of Use</div>
            </div>
            <div className='item'>
              Email:
              <a className='link' href="mailto:support@calpal-mail.topmusetech.com" style={{ color: "while" }}>support@calpal-mail.topmusetech.com</a>
            </div>
          </div>
        </div>

      </footer>
    </div>
  )
}

export default App
